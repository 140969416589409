.users-table{
    width: 90%;
    margin: 20px auto 0px auto;
}
.edit-users-form-container{
    margin-top: 20px;
}
.checkboxes{
    display: flex;
    align-items: center;
    .checkbox-box{
        display: flex;
        align-items: center;
    }
}
.select{
    width: calc(100% - 20px);
    height: 33px;
    border: rgb(179, 179, 179) 1px solid;
    background: white;
    margin-bottom: 24px;
    outline: none;
}
.submit-button-container{
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    button{
        background: #024dae;
        padding: 10px 150px;
        color: white;
        font-size: 20px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }
}
.select-form-control{
    min-width: 130px;
    padding: 5px;
    margin: 10px;
    border: rgb(224, 224, 224) 1px solid;
    background: white;
    border-radius: 8px;
    outline: none;
}
.checkbox-label{
    margin-right: 5px;
}
.filters-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.main-actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    button{
        background: #024dae;
        border: none;
        padding: 10px 30px;
        border-radius: 5px;
        color: white;
        cursor: pointer;
    }
}
.search-form{
    width: 160px;
    padding: 1px 3px;
    border: rgb(224, 224, 224) 1px solid;
    outline: none;
}