@import url("https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:ital,wght@0,600;1,400&family=Roboto:ital,wght@1,300&display=swap");
.policy .HeaderTitle {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin-top: 35px;
}

.policy .content {
  width: 85%;
  margin: auto;
  margin-top: 80px;
}

.policy .sectionTitle {
  font-size: 20px;
}

.policy .text {
  font-family: 'Lato', sans-serif;
  margin-top: 30px;
  line-height: 30px;
}

.policy .margin {
  margin-top: 45px;
}

.policy ul, .policy li {
  margin-left: 15px;
  list-style-type: circle;
  line-height: 25px;
}

.policy .marginbtn {
  margin-bottom: 150px;
}