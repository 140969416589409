.aside-modal.sm .content {
  width: 550px;
}

.aside-modal.md .content {
  width: 800px;
}

.aside-modal.lg .content {
  width: 1000px;
}

.aside-modal.elg .content {
  width: 1300px;
}

.aside-modal.fc .content {
  width: -moz-fit-content;
  width: fit-content;
}

.aside-modal.add-edit-post .content {
  max-width: 1000px;
}

.aside-modal.add-edit-post .content:not(div.multiselect-users-component) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.aside-modal.add-edit-post .content:not(div.multiselect-users-component) div.flex {
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 24px 50px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
}

.aside-modal.add-edit-post .content:not(div.multiselect-users-component) div.flex h2 {
  font-size: 24px;
  font-weight: bold;
}

.aside-modal {
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1039;
  opacity: 0;
  transition: all 0.7s;
}

.aside-modal.visible {
  transform: translateX(-100%);
  opacity: 1;
}

.aside-modal-shadow {
  display: block;
  position: absolute;
  top: 0;
  background-color: transparent;
  width: 200vw;
  height: 100%;
  transition: background 0.3s;
}

.aside-modal.visible .aside-modal-shadow {
  left: -100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.aside-modal > .content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  padding-left: 30px;
  overflow: auto;
  width: 900px;
  overflow-x: hidden;
}

.close-btn {
  position: absolute;
  top: 20px;
}

.aside-modal .close-btn {
  padding: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  border: none;
}

.aside-modal .close-btn img {
  width: 16px;
}

.template {
  display: none;
}

.aside-modal.visible {
  position: fixed;
}

.modal-dialog {
  pointer-events: unset;
}

.input-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  text-transform: capitalize;
}

.submit-btn {
  width: 158px;
  height: 38px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
  border: 0;
  background-color: #CF292D;
}

.submit-btn:hover {
  color: #fff;
}

@media only screen and (max-width: 950px) {
  .aside-modal > .content {
    width: 90%;
  }
}
.dataTables_wrapper .dataTables_filter {
  display: none;
}

.dataTables_wrapper .dataTables_length {
  display: none;
}

.dataTables_wrapper .top {
  display: none;
}

.dataTables_wrapper table {
  border-bottom: none !important;
}

.dataTables_wrapper table thead {
  color: black;
}

.dataTables_wrapper table thead tr th {
  padding: 8px 10px;
  vertical-align: middle;
}

.dataTables_wrapper table thead tr th:last-child {
  width: 5%;
}

.dataTables_wrapper table .table-menu {
  transform: scale(2);
  width: 20px;
  height: 20px;
}

.dataTables_wrapper table .dropdown-item {
  cursor: pointer;
}

.dataTables_wrapper table .dropdown-menu.show {
  left: -89px !important;
  width: -moz-max-content;
  width: max-content;
}

.dataTables_wrapper table .table-menu-button {
  outline: none !important;
  border: none !important;
  background: none !important;
}

.dataTables_wrapper table .table-menu-button:after {
  display: none;
}

table.dataTable tbody th, table.dataTable tbody td {
  vertical-align: middle;
}

.search_input {
  display: inline-block;
  width: calc(100% - 0px);
  background-color: #ffffff;
  color: black;
  border-radius: 4px;
  border: 1px solid #afafaf;
  height: 40px;
}

.search_input:focus {
  background-color: #ebebeb;
  color: black;
}

.modal-search-container .icon-search {
  position: absolute;
  top: 12px;
  right: 10px;
  color: #767676;
}

.modal-search-container {
  width: 100%;
  position: relative;
  height: 40px;
  top: 0;
}

.modal-search-container .form-group {
  position: absolute;
  z-index: 3;
  width: 100%;
}

.modal-search-container .modal-search-content .form-group {
  position: static;
  width: 100%;
}

.modal-search-content {
  max-width: 430px;
  width: 220%;
  min-height: 188px;
  border: 1px solid #aaaaaa;
  position: absolute;
  z-index: 2;
  right: -10px;
  top: -10px;
  background-color: white;
  display: none;
  border-radius: 5px;
  padding: 55px 30px 30px 10px;
}

.modal-search-content.ticket-search {
  max-width: 500px;
}

.modal-search-content.small-search {
  max-width: 425px;
}

.modal-search-container.user .modal-search-content {
  width: 160%;
  min-height: 100px;
  padding: 60px 20px 10px 20px;
}

.modal-search-content i {
  color: #767676;
}

.one-word {
  background-color: transparent;
  border: 1px solid #273037;
  color: #273037;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  padding-left: 27px;
  height: 28px;
  line-height: 28px;
  padding-right: 10px;
  text-align: left;
  position: relative;
  font-size: 1.1rem;
}

.one-word i {
  color: #273037 !important;
  font-size: 1.3rem;
  line-height: 20px;
}

.cut-out-word {
  font-size: 2.1rem;
  position: absolute;
  left: 5px;
  top: 3px;
  cursor: pointer;
}

.aside-modal {
  position: absolute;
}
.aside-modal .content {
  padding: 20px !important;
}
.aside-modal .close-button {
  margin-right: 20px;
  right: 20px !important;
}/*# sourceMappingURL=AsideModal.css.map */