.table-header {
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  padding: 12px;
  box-shadow: 0px 0px 15px 3px #c9c9c9;
  border-radius: 12px;

  .table-header-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .sort-icon {
    width: 15px;
  }

  .table-header-text {
    margin-left: 5px;
  }
}

ul{
  padding: 0px !important;
}

.table-body-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #024dae;
    cursor: pointer;
    color: white !important;
  }
}

.table-icon {
  width: 20px;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  span {
    margin: 0px 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
  }

  .active-page-number {
    color: #1ED8B9;
  }
}

.table-row {
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  padding: 12px;
  box-shadow: 0px 6px 15px 3px #c9c9c9;
  border-radius: 12px;
  margin-top: 3px;
}

.sort-button-dropdown {
  padding: 15px 3px;
  box-shadow: 0px 0px 15px 3px gray;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 150px;
  position: absolute;
  display: none;
}

.active .sort-button-dropdown {
  display: block;
}

.signed-up {
  background: #f3a41c;
  color: white !important;
}

.actions-container {
  display: flex;
  align-items: center;
  flex: 0.5;
}