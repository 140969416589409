.header-container {
  display: flex;
  align-items: center;
  background: #024dae;
  justify-content: center;
  height: 60px;
}
.header-container .nav-bar {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.header-container .nav-bar li {
  margin: 0px 20px;
  list-style-type: none;
}
.header-container .nav-bar li a {
  font-size: 18px;
  text-decoration: none;
  color: white;
}

.active-nav-link {
  color: wheat;
}

.header-logo {
  height: 40px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.logout-button {
  background: #B7b7b7;
  padding: 3px 15px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}/*# sourceMappingURL=Header.css.map */