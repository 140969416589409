body {
  background: rgb(243, 243, 243);
}

.auth-content-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-content-container .auth-images {
  display: flex;
  justify-content: center;
}
.auth-content-container .auth-images p {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
.auth-content-container .auth-images p span {
  color: #0584BE;
}
.auth-content-container .auth-logo-image {
  margin-bottom: 30px;
}
.auth-content-container .auth-title {
  text-align: center;
  margin-bottom: 32px;
  font-size: 24px;
}

.auth-logo {
  width: 292px;
  margin-bottom: 40px;
}

.auth-content-container .form-control {
  max-width: 400px !important;
  padding-left: 16px !important;
  height: 64px !important;
  width: 90vw !important;
  border-radius: 14px !important;
  border: 1px solid #DCDCDC !important;
}

.show-password {
  position: absolute;
  margin-right: 16px;
  cursor: pointer;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.form-group .form-label {
  margin-bottom: 4px;
  color: #262626;
  font-size: 16px;
}
.form-group .form-control {
  width: calc(100% - 20px);
  border: #9E9E9E 1px solid;
  border-radius: 4px;
  height: 32px;
  outline: none;
  padding-left: 14px;
  margin-bottom: 24px;
  color: #231F20;
}

.form-error {
  position: absolute;
  margin-top: 95px;
  font-size: 12px;
  color: #990000;
}

.auth-content-modal {
  padding: 30px;
  background: white;
  border-radius: 7px;
}

.auth-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.auth-gray-line {
  height: 1px;
  width: 100%;
  background: #D8D8D8;
  margin: 24px 0px;
}

.auth-submit-form-button {
  height: 64px;
  color: white;
  margin-top: 8px;
  width: 100%;
  border-radius: 14px;
  background: #0584BE;
  border: none;
  cursor: pointer;
}

.form-group .form-control-error {
  border-color: #E22820 !important;
  background: rgba(226, 40, 32, 0.06) !important;
}/*# sourceMappingURL=Auth.css.map */